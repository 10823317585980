import { $ } from 'hanako-ts/dist-legacy/Framework';
export class Pixelator {
    constructor(img, callback) {
        this.afterLoading = function () {
            this.ctx.drawImage(this.img, 0, 0, this.width, this.height);
            this.imgData = this.ctx.getImageData(0, 0, this.width, this.height).data;
            this.ctx.clearRect(0, 0, this.width, this.height);
            this.img.parentNode.replaceChild(this.canvas, this.img);
            this.currentResolution = 2;
            this.render();
            setTimeout(() => {
                this.appearInterval = window.setInterval(() => {
                    this.render();
                }, 80);
            }, 125);
        };
        this.render = function () {
            var pixelIndex;
            var red;
            var green;
            var blue;
            var pixelAlpha;
            var resolution = this.currentResolution;
            this.currentResolution += this.currentResolution;
            if (resolution >= 16 && !this.callbackCalled) {
                $(this.img).addClass('appeared');
                this.callbackCalled = true;
                this.callback();
            }
            if (this.clearAtEachStep)
                this.ctx.clearRect(0, 0, this.width, this.height);
            if (resolution >= this.width / 2) {
                this.ctx.drawImage(this.img, 0, 0, this.width, this.height);
                clearInterval(this.appearInterval);
                return;
            }
            var resolutionY = Math.floor(resolution / (this.width / this.height));
            var stepX = this.width / resolution;
            var stepY = this.height / resolutionY;
            for (var y = 0; y < resolutionY; y++) {
                for (var x = 0; x < resolution; x++) {
                    pixelIndex = (Math.floor(x * stepX) + (Math.floor(y * stepY) * this.width)) * 4;
                    red = this.imgData[pixelIndex + 0];
                    green = this.imgData[pixelIndex + 1];
                    blue = this.imgData[pixelIndex + 2];
                    pixelAlpha = this.imgData[pixelIndex + 3] / 255;
                    this.ctx.fillStyle = 'rgba(' + red + ',' + green + ',' + blue + ',' + pixelAlpha + ')';
                    this.ctx.fillRect(x * stepX, y * stepY, stepX + 1, stepY + 1);
                }
            }
        };
        this.img = img;
        this.callback = callback;
        this.callbackCalled = false;
        var split = this.img.src.split('.');
        this.clearAtEachStep = (split[split.length - 1] == 'png' || split[split.length - 1] == 'webp');
        this.canvas = document.createElement('canvas');
        this.ctx = this.canvas.getContext('2d');
        this.canvas.className = this.img.className;
        this.canvas.classList.add('appearing');
        this.canvas.id = this.img.id;
        this.canvas.width = this.width = Math.floor(this.img.width * window.devicePixelRatio);
        this.canvas.height = this.height = Math.floor(this.img.height * window.devicePixelRatio);
        if (!this.img.complete) {
            this.img.onload = () => {
                window.clearTimeout(this.forceSkipTimeout);
                this.afterLoading();
            };
            this.forceSkipTimeout = window.setTimeout(() => {
                this.afterLoading();
            }, 25000);
        }
        else {
            this.afterLoading();
        }
    }
}
