var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { $ } from 'hanako-ts/dist-legacy/Framework';
import { Component } from 'hanako-ts/dist-legacy/Component';
export class Menu extends Component {
    constructor() {
        super('Menu', false);
        this.triggerElementName = '#button-toggle-menu';
        this.menuElementName = '#menu';
    }
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            yield _super.init.call(this);
            this.triggerElement = $(this.triggerElementName);
            this.menuElement = $(this.menuElementName);
            if (window.location.toString() == 'https://kanakosawada.com/univers/' || window.location.toString() == 'https://kanakosawada.com/') {
                $('.menu-item-343').removeClass('current-menu-item');
            }
            if (this.triggerElement.length == 0 || this.menuElement.length == 0) {
                this.warning('missing `triggerElement` or `menuElement`');
            }
            else {
                if (this.triggerElement.hasClass('active-md') && window.innerWidth >= 768)
                    this.triggerElement.addClass('active').removeClass('active-md');
                this.triggerElement.on('click', (event) => {
                    event.preventDefault();
                    this.triggerElement.toggleClass('active');
                    if (this.menuElement.hasClass('d-md-block') && window.innerWidth >= 768) {
                        this.menuElement.addClass('d-none');
                        this.menuElement.toggleClass('d-md-block');
                    }
                    else {
                        this.menuElement.toggleClass('d-none');
                    }
                });
                //this.ajaxUpdateCartLink();
                /*jQuery(document.body).on('added_to_cart', (event: Event) => {
                  this.ajaxUpdateCartLink();
                });
          
                jQuery(document.body).on('removed_from_cart', (event: Event) => {
                  this.ajaxUpdateCartLink();
                });
          
                jQuery(document.body).on('wc_cart_emptied', (event: Event) => {
                  this.ajaxUpdateCartLink();
                });*/
                this.success();
            }
        });
    }
    ajaxUpdateCartLink() {
        return __awaiter(this, void 0, void 0, function* () {
            this.updateCartLink();
            const cartCount = parseInt(yield $.httpRequest({
                url: '/wp-admin/admin-ajax.php',
                type: 'POST',
                body: {
                    action: 'get_cart_count'
                },
                dataType: 'text'
            }));
            localStorage.setItem('cartCount', cartCount.toString());
            this.updateCartLink(cartCount);
        });
    }
    updateCartLink() {
        return __awaiter(this, arguments, void 0, function* (cartCount = 0) {
            cartCount = parseInt(localStorage.getItem('cartCount') || '0');
            localStorage.setItem('cartCount', cartCount.toString());
            if (cartCount > 0)
                $('.menu-item-1001').addClass('d-inline-block').removeClass('d-none').text('Panier (' + cartCount + ')');
        });
    }
}
