import { $ } from 'hanako-ts/dist-legacy/Framework';
import { Collection } from 'hanako-ts/dist-legacy/Collection';
function styleTextNodes(element) {
    var span = document.createElement('span');
    var textNodes = new Collection();
    (function recursiveWalk(node) {
        if (!node)
            return;
        var childNode = node.firstChild;
        while (childNode != null) {
            if (childNode.nodeType == 3) {
                textNodes.add(childNode);
            }
            else if (node.nodeType == 1) {
                recursiveWalk(childNode);
            }
            childNode = childNode.nextSibling;
        }
    })(element);
    textNodes.forEach((textNode) => {
        var dummy = document.createDocumentFragment();
        var text = textNode.textContent;
        var tempNode;
        for (var index = 0; index < text.length; index++) {
            tempNode = span.cloneNode(true);
            tempNode.textContent = text[index];
            dummy.appendChild(tempNode);
        }
        textNode.parentNode.replaceChild(dummy, textNode);
    });
}
export class Typewriter {
    constructor(item, callback) {
        this.item = item;
        this.callback = callback;
        this.callbackCalled = false;
        this.render();
    }
    render() {
        this.item.style.width = parseFloat(getComputedStyle(this.item, null).width.replace('px', '')) + 'px';
        this.item.style.height = parseFloat(getComputedStyle(this.item, null).height.replace('px', '')) + 'px';
        styleTextNodes(this.item);
        $(this.item).addClass('appearing');
        clearInterval(this.interval);
        setTimeout(() => {
            var speed = (this.item.tagName == 'P') ? 10 : 20;
            var step = 0;
            this.interval = window.setInterval(() => {
                var nextSpans;
                var range = Math.ceil(Math.pow(step, step / (50 - step)));
                nextSpans = new Collection();
                $(this.item).find('span:not(.visible)').forEach((item, index) => {
                    if (index < range)
                        nextSpans.add(item);
                });
                if (($(this.item).hasClass('appear-block') || this.item.querySelectorAll('span.visible').length >= this.item.querySelectorAll('span').length / 1) && !this.callbackCalled) {
                    $(this.item).addClass('appeared');
                    this.callbackCalled = true;
                    this.callback();
                }
                if (nextSpans.length == 0) {
                    clearInterval(this.interval);
                    $(this.item).find('span').forEach((child) => {
                        if ($(this.item).hasClass('map-container') || $(child).hasClass('corner'))
                            return;
                        child.replaceWith(child.textContent);
                    });
                    this.item.style.width = '';
                    this.item.style.height = '';
                    this.isAppearing = false;
                }
                else {
                    nextSpans.addClass('visible');
                }
                step++;
            }, speed);
        }, (this.appritionIndex == 0) ? 150 : 40);
    }
}
